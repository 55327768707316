@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* ::selection {
  background-color: #fff; /* Set the background color to green
  color: black; /* Optional: Set text color to white for better contrast
} */
html {
    scroll-behavior: smooth;
  }
  body{
    margin: 0;
    padding: 0;
}
body::-webkit-scrollbar {
    width: 5px;  /* Width of the scrollbar */
  }
  
  body::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #8215ff 40%, #fff 100%);
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  body::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track background color */
    border-radius: 10px; /* Rounded corners for the track */
  }
@font-face {
    font-family: "Suisse Int'l"; /* Name you give to the font */
    src: url('../fonts/fonnts.com-Suisse_Intl_Regular.ttf') format('opentype');
    /* font-weight: normal;  */
    /* font-style: normal;  */
}

.homepage_banner_sec{
    position: relative;
    overflow: hidden;
    background-color: #000;
}
.homepage_banner_sec video{
/* height: 500px; */
width: 100%;
background-size: cover;
}
.homepage_text{
    width: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.40) 100%);
backdrop-filter: blur(20px);
position: absolute;
bottom: 0;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 358px;
}
.homepage_text::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: url('../images/Line\ 1.svg')
        repeat-y;
        animation: moveBorder 10s linear infinite;
    }
    

@keyframes moveBorder {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}
.homepage_text h1{
    text-align: center;
font-family: "Open Sans";
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 751px;
background: linear-gradient(90deg, #FFF 75%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 55px;
padding-bottom: 24px;
margin-bottom: 0;
}
.homepage_text p{
    color: var(--Primary-Base-White, #FFF);
text-align: center;
font-family: "Suisse Int'l";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 158.824% */
width: 722px;
padding-bottom: 26px;
margin-bottom: 0;
}

.secont_banner_2{
    background: #000;
}

.secont_banner_2 h2{
padding-bottom: 70px

}
.blur_fade_video{
    border-radius: 24px !important;
border: 1px solid rgba(255, 255, 255, 0.00);
background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(152, 107, 230, 0.10) 50%, rgba(255, 255, 255, 0.00) 100%);
backdrop-filter: blur(10px);
width: 120px;
height: 120px;
flex-shrink: 0;
position: absolute;
z-index: 1000;
position: absolute;
top: -22px;
z-index: 100;
right: -26px;
}
.trust_logos{
    width: 60%;
    margin: 0 auto;
    padding-bottom: 70px;
}
/* Fade effects */
.fade-effect-left,
.fade-effect-right {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100px;
    height: 100%;
    pointer-events: none; /* Prevent blocking interactions */
}

.fade-effect-left {
    left: 0;
    background: linear-gradient(to right, black, transparent);
}

.fade-effect-right {
    right: 0;
    background: linear-gradient(to left, black, transparent);
}

/* Swiper container */
.swiper-container {
    position: relative;
    overflow: hidden;
}

.trust_logos h2{
    text-align: center;
font-family: "Open Sans";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 40px;
padding-top: 70px
}
.trust_line{
    margin-top: 70px;
    background: #2D2D2D;
    width: 1200px;
height: 1px;
margin: 0 auto;
}
.partner_sec{
    width: 60%;
    margin: 0 auto;
    padding-top: 140px;
}
.partner_logo{
    padding-bottom: 140px;
}
.partner_text h4{
    color: #998BE5;
text-align: center;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0;
padding-bottom: 4px;
}
.partner_text h3{
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
padding-bottom: 16px;
margin-bottom: 0;
}
.partner_text p{
    color: #EFEFEF;
text-align: center;
font-family: "Suisse Int'l";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 32px;
text-align: center;
padding-bottom: 32px;
margin-bottom: 0;
}
.partenr_mini{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.partenr_top{
    border-top: 1px solid #2D2D2D;

}
.partenr_main{
    border-right: 1px solid #2D2D2D;
}
.partenr_mini p{
    color: #EFEFEF;
    text-align: center;
    font-family: "Suisse Int'l";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    padding-top: 16px
}

.video_banner_sec{
    background-color: #000;
    padding-bottom: 140px;
}

.video_text_con{
    border-radius: 32px;
    border-right: 1px solid #2f2f2f;
    border-bottom: 1px solid #2f2f2f;
    /* border-bottom-width: 4; */
    overflow: hidden;
    padding: 10px 20px;
}

.video_text_con h4{
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
margin-bottom: 0;
}
.video_text_con_2{
    border-radius: 32px;
    border-left: 1px solid #2f2f2f;
    border-bottom: 1px solid #2f2f2f;
    /* border-bottom-width: 4; */
    overflow: hidden;
    padding: 10px 20px;
}

.video_text_con_2 h4{
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
margin-bottom: 0;
}
.video_section_2{
    box-shadow: 0px 0px 40px 0px #271B6D;
    border-radius: 24px;
    /* height: 8%; */
}
.video_section_2 video{
    width: 100%;
    border-radius: 24px;
border: 1px solid #484163;
box-shadow:20px 18px 4px 2px #21165675;
flex-shrink: 0;
}
.video_section{
    box-shadow: 0px 0px 40px 0px #271B6D;
    border-radius: 24px;
    height: 78%;
    position: relative; /* For positioning the pseudo-elements */
    display: inline-block;
}
.video_section video{
    width: 100% !important;
    border-radius: 24px !important;
    border: 1px solid #484163 !important;
    box-shadow: -20px 18px 4px 2px #21165675 !important;
}
/* .video_section video::before{
    content: '' !important;
    position: absolute !important;
    width: 800px !important;
    height: 140% !important;
    background-color: white !important;
    box-shadow: 10px 10px 10px white !important;
    animation: animate 4s linear infinite !important;
    z-index: 1000 !important;

} */
/* .video_section video::after{
    position: absolute !important;
    inset: 10px !important;
    background-color: blueviolet !important;
    z-index: 1000 !important;
}
@keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
} */
.swiper {
    background-color: transparent !important;
  }
  .swiper-slide {
    background-color: transparent !important;
  }
  .vertical_slider_text {
    background-color: transparent !important;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .swiper,
    .swiper-slide,
    .vertical_slider_text {
      background-color: transparent !important;
    }
  }
  
.trans_box{
    border-radius: 4px;
background: #998BE5;
width: 20px;
height: 16px;
}
.vertical_swipper_slider{
    height: fit-content;
}
.vertical_slider_text{
    height: fit-content;
}
.vertical_slider_text h5{
    color: var(--Primary-Base-White, #FFF);
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 8px;
    margin-bottom: 0;
}
.vertical_slider_text p{
color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 158.824% */
padding-bottom: 24px;
}
.fade-bottom {
    position: absolute;
    bottom: 0; /* Position the fade effect at the bottom */
    left: 0; /* Ensure it covers the full width */
    width: 100%; /* Full width of the container */
    height: 140px; /* Height of the fade effect (you can adjust this as needed) */
    background: linear-gradient(to bottom, transparent, black); /* Fade effect */
    pointer-events: none; /* Make sure the fade doesn't interfere with interactions */
    z-index: 100;
}
.our_products{
    background: #000;
    padding-bottom: 60px;
}

.our_pro_text p{
    color: #998BE5;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 4px;
}
.our_pro_text h5{
    text-align: center;
    font-family: "Open Sans";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 40px;
margin-bottom: 0;
}



.swiper-container {
  position: relative;
}

.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  margin-top: 24px !important;
  z-index: 10 !important;
}

.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background: #9c9c9c !important;
  transition: all 0.6s ease;  /* Adjusted for smoother transition */
}

.swiper-pagination-bullet-active {
  background: #998BE5 !important;
  width: 12px;
  transform: scale(1.3);  /* Keep the scale effect */
}

/* Create the transition effect between bullets */
.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  transform: scale(1);
  opacity: 0.5; /* Optional: Make inactive bullets fade */
}

.swiper-pagination-bullet-active {
  transform: scale(1.3);  /* Active bullet scaling */
  opacity: 1;  /* Ensure the active bullet is fully visible */
}
.xop_home_text img{
position: absolute;
/* background-color: rgb(142, 142, 0); */
z-index: 1000;
top: 45%;
left: 45%;
}
.xop_home_text p{
position: absolute;
/* background-color: rgb(142, 142, 0); */
z-index: 1000;
top: 45%;
left: 45%;
}
.over_lay_out{
    position: absolute;
    opacity: 0.54;
background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 58%, #000 100%);
height: 100%;
z-index: 100;
width: 100%;
}
.our_pro_box{
    border-radius: 12px;
    border: 1px solid #4F477E;
    background: #312C4E;
    padding: 20px;
    position: relative;
    z-index: 1000;
}
.product_fade{
    display: none;
    width: 100%;
height: 900px;
background: radial-gradient(57.13% 57.13% at 50% 50%, #4C38B8 0%, rgba(6, 6, 6, 0.00) 100%);
position: absolute;
top:-50;
z-index: 0;
}
.product_img{
    border-right: 1px solid #060606;
}
.product_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product_text h5{
    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 24px;
}
.product_text p{
    color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 180% */
padding: 8px 0px;
}
.product_text a{

color: #998BE5;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 180% */
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}

.world_map_sec{
    background-color: #000;
    padding: 100px 0px 0px 0px;   
    height: 820px; 
}
.world_map_sec img{
    width: 90%;
    height: 680px;
    flex-shrink: 0;
    object-fit: contain;
}
.map_mini_box{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 570px;
    right: 360px;
}
.map_mini_box_2{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 536px;
    left: 347px;
}
.inside_box{
    border-radius: 12px;
border: 0.8px solid #998BE5;
background: rgba(50, 50, 50, 0.40);
box-shadow: 0px 0px 20px 0px rgba(105, 96, 151, 0.50);
backdrop-filter: blur(5px);
width: 320px;
/* height: 160px; */
flex-shrink: 0;
padding: 20px;

}
.inside_box p:nth-child(1){
    color: #FFF;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 133.333% */
padding-bottom: 9px;
margin-bottom: 0;
}
.inside_box p:nth-child(2){
    color: #EFEFEF;
    font-family: "Suisse Int'l";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 180% */
    margin-bottom: 0;
}
.client_review_sec{
    background: #171717;
    padding: 60px 0px 80px 0px;
}
.client_rev_text h6{
    text-align: center;
    font-family: "Open Sans";
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 40px;
margin-bottom: 0;
}
.client_rev_text p:nth-child(2){
    text-align: center;
    color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 18px;
font-style: italic;
font-weight: 600;
line-height: 32px; /* 177.778% */
width: 737px;
margin: 0 auto;
padding-bottom: 24px;

}
.client_rev_text p:nth-child(3){
    color: #FFF;
    font-family: "Suisse Int'l";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;
    text-align: center;
    margin-bottom: 0;

}
.client_rev_text p:nth-child(4){
    text-align: center;
    align-self: stretch;
    color: #998BE5;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 32px;
margin-bottom: 0;
}
.partner_padding{
    padding: 30px;
}
.map_mbl_text_box{
    border-radius: 12px;
border: 0.8px solid #998BE5;
background: rgba(50, 50, 50, 0.40);
box-shadow: 0px 0px 20px 0px rgba(105, 96, 151, 0.50);
backdrop-filter: blur(5px);
width: 272px;
height: 281px;
flex-shrink: 0;
padding: 27px;
  }
  .map_mbl_text_box h6{
    color: #FFF;
    font-family: "Suisse Int'l";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    padding-bottom: 9px;
    margin-bottom: 0;
  }
  .map_mbl_text_box p{
    align-self: stretch;
    color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 192.857% */
padding-bottom: 20px;
margin-bottom: 0;
  }
  .map_mbl_text_box p:nth-child(2){
/* color: red !important; */
border-bottom: 1px solid #2D2D2D;
margin-bottom: 20px;
  }
@media screen and (max-width: 800px) {
    .our_products{
        background: #000;
        padding-bottom: 30px;
    }
    
    .video_banner_sec{
        background-color: #000;
        padding-bottom: 100px;
    }
    .partner_logo{
        padding-bottom: 100px;
    }
    .client_review_sec{
        background: #171717;
        padding: 40px 0px 40px 0px;
    }
    .our_pro_box{
        border-radius: 12px;
        border: 1px solid #4F477E;
        background: #312C4E;
        padding: 16px;
        position: relative;
        z-index: 1000;
    }
    .homepage_text h1{
        font-size: 28px;
        width: 90%;
        padding-bottom: 10px;
        padding-top: 16px;
    }
    .homepage_banner_sec{
        height: 400px;
    }
    
    .homepage_text p{
        width: 90%;
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 10px;

    }
    .homepage_text{
        height: 248px;
    }
    .partner_sec{
        width: 100%;
        padding-top: 100px  ;
    }
    .partner_text h4{
        color: #998BE5;
text-align: center;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
    }
    .partner_text h3{
        text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .partner_text p{
        color: #EFEFEF;
text-align: center;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
    }
    .partenr_mini p{
        color: #EFEFEF;
text-align: center;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20.565px; /* 146.891% */
white-space: nowrap;
    }
.partner_padding{
    padding: 20px;
}
.video_text_con h4{
    font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.vertical_slider_text h5{
    color: #FFF;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.vertical_slider_text p{
    color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
.trans_box{
    border-radius: 4px;
background: #998BE5;
width: 20px;
height: 12px;
}
.video_text_con_2 h4{
    font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.our_pro_text p{
    color: #998BE5;
text-align: center;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.our_pro_text h5{
    text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.product_text h5{
    color: #FFF;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.product_text p{
    align-self: stretch;
    color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
.product_img{
    border: none;
}
.client_rev_text h6{
    text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom: 24px;
}
.client_rev_text p:nth-child(2){
    color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 14px;
font-style: italic;
font-weight: 600;
line-height: 32px; /* 228.571% */
width: 100%;
}
.client_rev_text p:nth-child(3){
    color: #FFF;
font-family: "Suisse Int'l";
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.client_rev_text p:nth-child(4){
    color: #998BE5;
font-family: "Suisse Int'l";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.mySwiper2 {
    width: 100%;
    height: 390px !important;
  }
  .world_map_sec{
    height: 690px;
  }
  .world_map_sec img {
    width: 100% !important;
    height: 198px !important;
    object-fit: contain;

  }
  .map_mbl_text_box{
    border-radius: 12px;
border: 0.8px solid #998BE5;
background: rgba(50, 50, 50, 0.40);
box-shadow: 0px 0px 20px 0px rgba(105, 96, 151, 0.50);
backdrop-filter: blur(5px);
width: 272px;
height: 281px;
flex-shrink: 0;
padding: 27px;
  }
  .map_mbl_text_box h6{
    color: #FFF;
    font-family: "Suisse Int'l";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    padding-bottom: 9px;
    margin-bottom: 0;
  }
  .map_mbl_text_box p{
    align-self: stretch;
    color: #EFEFEF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 192.857% */
padding-bottom: 20px;
margin-bottom: 0;
  }
  .map_mbl_text_box p:nth-child(2){
/* color: red !important; */
border-bottom: 1px solid #2D2D2D;
margin-bottom: 20px;
  }
  .world_map_sec{
    background-color: #000;
    padding: 100px 0px 0px 0px;   
    height: 720px; 
}
.xop_home_text img {
    position: absolute;
    z-index: 100;
    top: 24%;
    left: 45%;
    width: 80px;
}
.xop_home_text p {
    position: absolute;
    z-index: 100;
    font-size: 20px !important;
    top: 24%;
    left: 45%;
    width: 80px;
    -webkit-text-stroke-width: 0.7px !important; /* Adjust stroke width as needed */
    -webkit-text-stroke-color: rgba(135, 120, 251, 0.553) !important; /* Stroke color */
}
.homepage_banner_sec video{
    /* margin-top: 80px; */
    height: 379px;
    width: 100%;
    object-fit: cover !important;
    }
    
}


  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  body {
    background: #000;
    color: #000;
  }
  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    /* height: 80%; */
    width: 100%;
    height: 320px;
  }
  .mySwiper2{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .SubSwiper {
    height: 50px;
    width: 250px;
    box-sizing: border-box;
    padding: 10px 0;

  }
  .SubSwiper .swiper-wrapper{
    display: flex !important;
    justify-content: center !important;
  }
  .SubSwiper .swiper-slide {
    opacity: 0.4;
    filter: grayscale(80%); 
    height: 40px;
    width: 40px !important;
    margin: auto 0;
  }
  
  .SubSwiper .swiper-slide-thumb-active {
    opacity: 1;
    filter: grayscale(0%); 
    height: 50px;
    width: 50px !important;

  }
  
  .swiper-slide img {
    object-fit: cover;
  }
  .swiper-backface-hidden .swiper-slide {
    background: none;
  }

  @media screen and (max-width: 1300px) {
    .map_mini_box_2 {
        position: relative;
        bottom: 537px;
        left: 321px;
    }
    .map_mini_box {
        position: relative;
        bottom: 548px;
        right: 284px;
    }
  }
  @media screen and (max-width: 1200px) {
    .map_mini_box_2 {
        position: relative;
        bottom: 539px;
         left: 297px;
    }
    .map_mini_box {
        position: relative;
        bottom: 529px;
        right: 209px;
    }

  }
  @media screen and (max-width: 960px) {
    .world_map_sec img {
        height: 370px;
        
 }
  }

  
  .xops_home_text_con {
    -webkit-text-stroke-width: 2px !important; /* Adjust stroke width as needed */
    -webkit-text-stroke-color: rgba(135, 120, 251, 0.434) !important; /* Stroke color */
    font-family: "Open Sans" !important;
    font-size: 54.923px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;
    /* backdrop-filter: blur(5.769230842590332px) !important; */
    color: transparent !important; /* Make text color transparent */
}
