.navbar_all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    /* background-color: transparent !important; */
    z-index: 1000;
    position: fixed;
    border-bottom: 1px solid rgba(255, 255, 255, 0.00);
    background: rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(4px);
    
}
.xt_logo{
  padding-right: 80px;
}
.nav_btns{
    display: flex;
    align-items: center;
    gap: 24px;
}
.nav_login_btn button{
    display: flex;
width: 100px;
height: 48px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
border-top: 0.8px solid rgba(133, 146, 237, 0.00);
border-bottom: 0.8px solid rgba(133, 146, 237, 0.00);
background: var(--Primary-Base-White, #FFF);
border: none;
}
.nav_demo_btn button{
  white-space: nowrap;
    display: flex;
height: 48px;
padding: 10px 20px;
align-items: flex-end;
gap: 7px;
border-radius: 8px;
border-top: 0.8px solid rgba(161, 173, 252, 0.00);
border-bottom: 0.8px solid rgba(161, 173, 252, 0.00);
background: #463B81;
color: var(--Primary-Base-White, #FFF);
font-family: "Suisse Int'l";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.nav_menu ul {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav_menu ul li {
    cursor: pointer;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
  }
  .nav_menu ul li a{
    cursor: pointer;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .nav_menu ul li:hover {
    color: #998BE5;
  }
  .nav_menu ul li a:active {
    color: #998BE5;
  }
  /* Footer css */
.footer_des img{
  margin-bottom: 24px;
  width: 60px !important;
  height: 60px !important;
}
.footer_all{
/* border-top:  #2D2D2D 1px solid; */
padding-top: 60px;
overflow: hidden;

}
.footer_all{
  background: #171717;
  /* padding: 55px 10px; */
}
.left_sec h2{
  color: var(--Primary-Base-White, #FFF);
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
  margin-bottom: 0;
}
.left_sec p{
  color: #EFEFEF;
/* font-family: "Suisse Int'l"; */

margin-bottom: 0;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 200% */
}
.input_sec{
  display: flex !important;
  align-items: center !important;
  gap: 4px;
  padding-top: 16px;
  flex-direction: row !important;
  flex-wrap: wrap;
}
.input_sec input{
  display: flex;
  width: 343px;
  height: 40px;
  padding: 13px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
border: 0.8px solid rgba(255, 255, 255, 0.084);
background: #1D1D1D;
outline: none;
color: white;
}
.input_sec input:placeholder-shown{
  color: #9B9B9B;
font-family: "Suisse Int'l";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 207.692% */
}
.input_sec button{
  display: flex;
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
border-top: 0.8px solid rgba(161, 173, 252, 0.00);
border-bottom: 0.8px solid rgba(161, 173, 252, 0.00);
background: #463B81;
color: #FFF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 200;
line-height: normal;
}
.custom_hr {
  width: 343px;
  height: 4px;
  background: #2D2D2D;
  margin: 32px 0;
}
.groww{
  padding-top: 40px;
}

.terms{
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  text-decoration: underline;
}
.reserve_sec{
  display: block;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.cookies p{
  text-decoration: underline;
  padding-bottom: 60px;
}
.ful_page_overlay{
  
}
.connav_fade{
  border-radius: 716px;
opacity: 0.5;
background: #8A86F2;
mix-blend-mode: plus-lighter;
filter: blur(10px);
width: 40%;
height: 7px;
flex-shrink: 0;
position: absolute;
bottom: -3px;
}
.main_nav_fade{
  border-radius: 716px;
  background: #8A86F2;
  mix-blend-mode: plus-lighter;
  filter: blur(34px);
  width: 40%;
height: 11px;
flex-shrink: 0;
  position: absolute;
bottom: -3px;
}
.cookies p,.company p{
  color: #BBB;
text-align: center;
font-family: "Suisse Int'l";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.right_sec h4{
  color: #FFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 24px;
  margin-bottom: 0;
}
.right_sec ul{
  margin: 0;
  padding: 0;
}
.right_sec li{
  padding: 0;
  margin: 0;
  list-style: none;
  color: #BBB;
font-family: "Suisse Int'l";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 10px;
}
.footer_all a{
  text-decoration: none;
  color: #bbb;
}
.social_icons{
  display: flex;
  align-items: center;
  gap: 16px;
}
.social h2{
  color: var(--Primary-Base-White, #FFF);
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 0;
padding-bottom: 16px;
}
.right_sec li span{
  color: #FFF;
  font-family: "Suisse Int'l";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */
}



/* Base navbar styling */
.navbar_all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Logo container */
.logo_container {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  padding-right:0px;
}

/* Burger Icon */
.mobile_menu_icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1100;
}

/* Navigation Menu */
.nav_list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav_list.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100vh;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgb(0, 0, 0) 10%, rgb(33, 25, 47) 100%);
  backdrop-filter: blur(80px);
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-in-out;
  z-index: 10000 !important;
  padding-top: 75px;
}
.swiper{
  background: none !important;
}
.nav_list li {
  margin-bottom: 16px;
}
.close_btn {
  position: absolute;
  right: 35px;
  top: 38px;
  cursor: pointer;
}
.nav_list li a {
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s color ease-in-out;
  font-family: "Suisse Int'l";
  font-size: 13px;
  font-style: normal;
  line-height: 20px; /* 153.846% */
}
.nav_list li:hover {
  color: #998BE5 !important;
}

/* Sliding animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.contact_btn a{
  display: inline-flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 8px;
border-top: 0.8px solid rgba(161, 173, 252, 0.00);
border-bottom: 0.8px solid rgba(161, 173, 252, 0.00);
background: #463B81;
color: #FFF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration: none;
position: absolute;
right: 3%;
}
.contact_btn_desk a{
  display: inline-flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 8px;
border-top: 0.8px solid rgba(161, 173, 252, 0.00);
border-bottom: 0.8px solid rgba(161, 173, 252, 0.00);
background: #463B81;
color: #FFF;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration: none;
margin-left: 160px;
}
.company p{
  padding-bottom: 24px;
  margin-bottom: 0;
}
/* Responsive Styling */
@media (min-width: 769px) {
  .input_sec{
    display: flex !important;
    align-items: center !important;
    gap: 4px;
    padding-top: 16px;
    flex-direction: row !important;
    flex-wrap: nowrap;
  }
  .company p{
    padding-bottom: 60px;
    margin-bottom: 0;
  }
  .footer_des img{
    width: 80px !important;
    height: 80px !important;
  }
  .nav_list li {
    margin: 20px 0;
  }
  
.nav_list li a {
  color: white !important;
}
  .right_sec li span{
    color: #FFF;
  font-family: "Suisse Int'l";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  }
  
  .right_sec h4{
    color: var(--Primary-Base-White, #FFF);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 24px;
  margin-bottom: 0;
  }
  
.right_sec li{
  padding: 0;
  margin: 0;
  list-style: none;
  color: #BBB;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 10px;
}
  .mobile_menu_icon {
    display: none;
  }

  .logo_container {
    justify-content: flex-start;
  }
  .social h2{
    color: var(--Primary-Base-White, #FFF);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  padding-bottom: 16px;
  }
  .nav_list {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
.logo_container{
padding-right: 160px;
}
  .nav_list li {
    margin: 0;
  }
  .custom_hr {
    width: 100%;
    margin: 20px 0; 
    height: 4px;
    background: #2D2D2D;
  }
  .nav_list li a:hover {
    color: #998BE5 !important;
  }
  .nav_list li a{
    color: #fff;
  }
  .contact_btn a{
display: none;
  }
  .footer_all{
    background: #171717;
    /* padding: 55px; */
  }
  .reserve_sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
