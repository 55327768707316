.box {
    position: relative;
    width: 30%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    border-radius: 20px;
  }
  
  .video_container {
    position: relative;
    width: 70%; /* Adjust the size as needed */
    height: 70%;
    overflow: hidden;
  }
  
  .animated_border {
    position: absolute;
    inset: 0;
    border: 5px solid transparent;
    border-radius: 24px;
    background: linear-gradient(90deg, rgba(160, 0, 0, 1), rgba(4, 75, 97, 1)) border-box;
    mask-composite: exclude; /* Ensures the border animation is outside */
    animation: animate-border 4s linear infinite;
  }
  
  @keyframes animate-border {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    box-shadow: 0px 0px 40px 0px rgba(39, 27, 109, 0.5);
    border: 1px solid #484163;
  }
  